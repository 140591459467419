import { window } from "browser-monads"
import queryString from "query-string"
const { authURL, location } = window


export const makeSelectOptions = (arr = [], labelPath, valuePath) => arr.map(o => ({
  label: o[labelPath],
  value: o[valuePath]
}));

export const getSearchQueryParam = (key, defaultValue="")  => {
  const queryParams = queryString.parse(location.search)
  return queryParams[key] || defaultValue
}


export const getServiceQueryParam = () => {
  return getSearchQueryParam("service", "")
}

export const getPathQueryParam = () => {
  return getSearchQueryParam("path", "")
}


export const isNumeric = name => {
  const allNumericRegex = /^[0-9]+$/m
  return allNumericRegex.test(name)
}


export const redirectToGoogleOAuth = (ripURL) => {
  const {authURL: ripUrlWindow, location : {origin}} = window

  var oauthURL = `${ripURL || ripUrlWindow || authURL}user/openid/google/login${getRedirectPathFromUrl()}&origin=${encodeURI(origin)}`
  console.log(oauthURL)
  return oauthURL
}



export const getRedirectPathFromUrl = () => {
  const serviceQueryParam = getServiceQueryParam()
  const pathQueryParam = getPathQueryParam()

  return `?${serviceQueryParam &&
    `service=${serviceQueryParam}`}${pathQueryParam &&
    `&path=${encodeURIComponent(pathQueryParam)}`}`
}

export const getTokenRedirectPathFromUrl = (token, expiry_at) => {
  const tokenPath = btoa(JSON.stringify({token,expiry_at}))
  const path = getRedirectPathFromUrl()+ `&enc_t=${tokenPath}`
  return path
}

export const API_CALL_STATUSES = {
  IDLE: 0,
  PROGRESS: 1,
  SUCCESS: 2,
  ERROR: 3,
}

export const extend = (...args) => {
  const [target] = args
  Array.prototype.slice.call(args, 1).forEach(function(source) {
    for (const key in source) {
      if (source[key] !== undefined) {
        target[key] = source[key]
      }
    }
  })
  return target
}

export const ajax = (...args) => {
  let settings = args.length === 1 ? args[0] : args[1]

  const emptyFunction = function() {}

  const defaultSettings = {
    path: "",
    headers: { "Content-Type": "application/json;charset=UTF-8" },
    type: "GET",
    data: {},
    host: authURL,
    success: emptyFunction,
    error: emptyFunction,
  }

  settings = {
    ...defaultSettings,
    ...(settings || {}),
  }

  const mimeTypes = {
    "application/json": "json",
    "text/html": "html",
    "text/plain": "text",
  }

  settings.url = settings.host + settings.path

  const success = function(data, xhr, settings) {
    settings.success(data, xhr.status, xhr)
  }

  const error = function(error, type, xhr, settings) {
    settings.error(xhr, xhr.responseText ? JSON.parse(xhr.responseText) : {})
  }

  const xhr = new XMLHttpRequest()

  xhr.addEventListener(
    "readystatechange",
    function() {
      if (xhr.readyState === 4) {
        let result, dataType

        if ((xhr.status >= 200 && xhr.status < 300) || xhr.status === 304) {
          const mime = xhr.getResponseHeader("content-type")
          dataType = mimeTypes[mime] || "json"
          result = xhr.responseText

          try {
            if (dataType === "json") {
              result = JSON.parse(result)
            }

            success(result, xhr, settings)
            return
          } catch (e) {}
        } else {
          error(null, "error", xhr, settings)
        }
      }
    },
    false
  )

  xhr.open(settings.type, settings.url)

  for (const key in settings.headers) {
    xhr.setRequestHeader(key, settings.headers[key])
  }

  /**
   * Send data format as JSON by default
   */
  xhr.send(JSON.stringify(settings.data))

  return this
}

export const authAjax = settings => {
  const token = getCookie("token")
  ajax({
    ...settings,
    headers: {
      ...(settings.headers || {}),
      Authorization: `Bearer ${token}`,
    },
  })
}

export const setCookie = (cname, cvalue, exAt) => {
  const { location } = window
  const d = new Date()
  d.setTime(new Date(exAt))

  const expires = d.toUTCString()
  const domainName = location.hostname
    .split(".")
    .slice(-2)
    .join(".")

  document.cookie =
    cname +
    "=" +
    cvalue +
    ";expires=" +
    expires +
    ";Domain=" +
    domainName +
    ";Path=/;secure; SameSite=None;"
}

export const getCookie = cname => {
  if (typeof document === `undefined`) return false
  const name = cname + "="
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(";")

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]

    while (c.charAt(0) == " ") {
      c = c.substring(1)
    }

    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

export const deleteCookie = name => {
  const { location } = window
  const domainName = location.hostname
    .split(".")
    .slice(-2)
    .join(".")

  document.cookie =
    name +
    "=;Domain=" +
    domainName +
    ";Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"
}


export const setLoginTokens = () => {
  const token_b64 = getSearchQueryParam("enc_t")
  const {token, expiry_at} = JSON.parse(atob(token_b64))
  
  if(token && token!== "")
  {
    if(expiry_at && expiry_at != "")
      setCookie("token", token, expiry_at)
    else 
    {
      var now = new Date();
      var time = now.getTime();
      var expireTime = time + 1000*36000*48;
      setCookie("token", token, expireTime);
    }
    setCookie("rio_loggedin_once", "yes", '01-01-2022')
    return true
  }
  
  return false
}


export const redirect = path => {
  window.location.href = path
}

export const formDataJSON = formEl => {
  const reqObj = {}
  const formData = new FormData(formEl)
  formData.forEach(function(value, key) {
    reqObj[key] = value
  })
  return reqObj
}

export const getQueryParam = (name, url) => {
  if (!url) {
    url = window.location.href
  }
  name = name.replace(/[\[\]]/g, "\\$&")

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)")
  const results = regex.exec(url)

  if (!results) return null

  if (!results[2]) return ""

  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

export const redirectViaVerifyAPI = () => {
   const REDIRECT_API_URL = `user/redirect${getRedirectPathFromUrl()}`
   window.location = authURL + REDIRECT_API_URL
}

export const PLAN_DISPLAY_TYPES = {
  COMMUNITY: "Community",
  PROFESSIONAL: "Professional",
}

const getSubscriptionTextArray = (
  displayName,
  subscriptionCharge,
  trialPeriodMonths
) => {
  if (displayName === PLAN_DISPLAY_TYPES.COMMUNITY) {
    return [
      `Free for ${trialPeriodMonths} months`,
      `$ ${subscriptionCharge}/ month`,
      "post expiry of trial period.",
    ]
  }

  return [`$ ${subscriptionCharge}/ month`]
}

const getPlanComponents = planComponents => {
  return planComponents.map(component => {
    const {
      name,
      includedUnits,
      chargeType,
      charge,
      planLimits,
      unit,
      metadata,
    } = component

    if (chargeType === "overage") {
      const { maxLimit } = (planLimits && planLimits[0]) || {};

      return {
        isTable: true,
        name,
        chargeType,
        charge,
        unit,
        maximum: maxLimit,
        min: includedUnits,
        originalData: component,
      }
    }

    if (chargeType === "add-on") {
      const { maxLimit } = planLimits[0]

      return {
        isTable: true,
        name,
        chargeType,
        unit,
        charge,
        maximum: maxLimit,
        min: includedUnits,
        originalData: component,
      }
    }

    const isFeature = name === "Features"
    return {
      isFeature,
      metadata: isFeature ? metadata.split("-") : [metadata],
    }
  })
}

export const getProcessedPlanData = (plansCollection = []) => {
  const publicPlansOnly = plansCollection.filter(
    ({ planType }) => planType === "Public"
  )

  return publicPlansOnly.map(plan => {
    const {
      displayName,
      subscriptionCharge,
      planComponents,
      trialPeriodMonths,
    } = plan
    const displayableComponents = planComponents.filter(({ isDisplay }) => {
      return isDisplay
    })

    return {
      displayName,
      subscriptionTextArray: getSubscriptionTextArray(
        displayName,
        subscriptionCharge,
        trialPeriodMonths
      ),
      planComponents: getPlanComponents(displayableComponents),
    }
  })
}

export const getIoRedirectUrl = () =>  {
  const { ioConsole } =  window;
  return ioConsole;
}
